<template>
  <SelectSimple v-model="internalValue" :items="items" label="Relevância" item-value="itemValue" />
</template>

<script>
export default {
  name: 'RelevanceSelect',

  components: {
    SelectSimple: () => import('../../commons/SelectSimple.vue'),
  },

  props: {
    value: {
      type: [Boolean, String, Number, Array, Object],
      default: null,
    },
  },

  data: () => ({
    internalValue: null,
    items: [
      { itemTitle: 'Relevante', itemValue: true },
      { itemTitle: 'Não Relevante', itemValue: false },
    ],
  }),

  watch: {
    value(newValue) {
      this.internalValue = newValue;
    },

    internalValue(newValue) {
      // emit an 'input' to update v-model in parent
      this.$emit('input', newValue);

      // emit an 'change' to trigger @change in parent
      this.$emit('change', newValue);
    },
  },

  created() {
    this.internalValue = this.value;
  },
};
</script>
